function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import React from "react";
import cx from "classnames";
import { XCircleIcon } from '@heroicons/react/24/outline';
import _InputGroup from "@double2-us/registry-airmodules__ui/src/primitives/components/presentational/InputGroup";
import Flex from "@double2-us/registry-airmodules__ui/src/primitives/components/presentational/Flex";
import { jsx as ___EmotionJSX } from "@emotion/react";
const sx = {
  light: {},
  dark: {
    _container: {},
    _control: {},
    _label: {},
    _error: {},
    _helpText: {},
    __focused: {
      _label: {},
      _control: {
        border: '1px solid #669df6',
        outline: '1px solid #669df6'
      }
    },
    __hovered: {
      _control: {
        border: '1px solid #9aa0a6'
      }
    }
  }
};
function InputGroup(props) {
  return ___EmotionJSX(_InputGroup, _extends({}, props, {
    variant: "DEFAULT",
    classNames: {
      container: "relative font-['Roboto'] text-[#5f6367]",
      control: cx('cursor-text flex flex-col text-[15px] border-[1px] rounded-[4px] py-[16px] my-1 pl-4 pr-2', props?.error ? 'border-[#B3261E]' : 'border-[#dadce0]'),
      label: ' text-[14px] px-1',
      helpText: 'text-[12px] text-[#49454F60]',
      error: 'text-[12px] text-[#B3261E]'
    },
    sx: sx.dark
  }, !!props?.value ? {
    trailingBlock: ___EmotionJSX(Flex, {
      className: "flex-1 items-center justify-center"
    }, ___EmotionJSX(XCircleIcon, {
      onClick: () => null,
      className: "cursor-pointer w-6 h-6"
    }))
  } : {}));
}
export default InputGroup;