import React from 'react';
import ReactDOM from 'react-dom';
import { isReady } from "@everymundo/registry-script";
import AirModule from "./AirModule";
import { jsx as ___EmotionJSX } from "@emotion/react";
isReady().then(async payload => {
  ReactDOM.render(___EmotionJSX(React.StrictMode, null, ___EmotionJSX(AirModule, {
    context: payload.context,
    payload: payload
  })), document.getElementById('root'));
});