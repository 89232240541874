import PropTypes from 'prop-types';
export const propTypes = {
  className: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dir: PropTypes.oneOf(['ltr', 'rtl']),
  onClick: PropTypes.func
};
export const defaultProps = {
  className: '',
  sx: {}
};