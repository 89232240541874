import isSSR from '../isSSR';

/**
 * @function getFocusableChildren
 * @param {Node} target
 * @returns array with all focusable children elements
 */
const getFocusableChildren = target => {
  if (isSSR() || target == null || !(target instanceof Node)) return [];
  return target.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
};
export default getFocusableChildren;