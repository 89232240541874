import PropTypes from 'prop-types';
import commonPropTypes from '../common/propTypes';
export const propTypes = {
  children: commonPropTypes.node.isRequired,
  leadingBlock: commonPropTypes.node,
  trailingBlock: commonPropTypes.node,
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelFor: PropTypes.string,
  isLabelForSROnly: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  variant: PropTypes.oneOf(['DEFAULT', 'INSET', 'INLINE']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    control: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    helpText: PropTypes.string
  }),
  sx: PropTypes.shape({
    _container: PropTypes.object,
    _control: PropTypes.object,
    _label: PropTypes.object,
    _error: PropTypes.object,
    _helpText: PropTypes.object,
    /**
     * we support these hovered / focused states because we
     * want to be able to apply styles to the inner elements
     * when the whole input group is focused / hovered
     * eg: apply specific text color to label when the input
     * group is focused
     */
    __hovered: PropTypes.shape({
      _container: PropTypes.object,
      _control: PropTypes.object,
      _label: PropTypes.object,
      _error: PropTypes.object,
      _helpText: PropTypes.object
    }),
    __focused: PropTypes.shape({
      _container: PropTypes.object,
      _control: PropTypes.object,
      _label: PropTypes.object,
      _error: PropTypes.object,
      _helpText: PropTypes.object
    })
  })
};
export const defaultProps = {
  variant: 'DEFAULT'
};